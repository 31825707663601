import clsx from 'clsx';
import __capitalize from 'lodash/capitalize';
import __isEmpty from 'lodash/isEmpty';
import __isUndefined from 'lodash/isUndefined';
import { dataResources } from 'utils/constants';
import { getNomCanal } from 'utils/nomCanalHelper';

const { logosPath } = dataResources;

export default function LogoDirecte(props) {
    const { canal, logo, className, tipus, customText } = props;

    let icona = false;

    const _teLogo = () => {
        if (__isUndefined(logo)) {
            return false;
        }
        switch (logo.toLowerCase()) {
            case 'no':
            case '_':
            case '-':
            case '__':
                return false;

            default:
                return true;
        }
    };

    const stylesLogo = clsx({
        [className]: !__isEmpty(className)
    });

    const isOca = canal?.startsWith('oca');

    switch (canal) {
        case 'c33':
        case '33d':
        case 'cs3/33d':
            icona = '33';
            break;
        default:
            icona = canal === 'BEAUTIES' || canal?.startsWith('PUCFC') || canal?.startsWith('PUCOCA') ? canal.toLowerCase() : canal;
            break;
    }

    if (isOca) {
        if (_teLogo()) {
            icona = 'oca_' + logo.toLowerCase();
        } else if (tipus === 'radio') {
            icona = 'oca_cr';
        } else {
            icona = 'oca_3cat';
        }
    } else {
        switch (canal) {
            case 'e3':
                icona = 'esport3';
                break;
            case 'tv3':
                icona = 'tv3';
                break;
            case 'tv3cat':
                icona = 'tv3cat';
                break;
            case '324':
                icona = '324';
                break;
            case 'c33':
            case '33d':
            case 'cs3/33d':
            case '33':
                icona = '33';
                break;
            case 's3':
            case 's3s3':
            case 's3x3':
            case 'sx3':
                icona = 'sx3';
                break;
            case 'cr':
                icona = 'catradio';
                break;
            case 'ci':
                icona = 'ci';
                break;
            case 'icat':
                icona = 'ic';
                break;
            case 'cm':
                icona = 'cm';
                break;
            case 'crei':
                icona = 'ocr1';
                break;
            case 'fc1':
                icona = 'pucfc1';
                break;
            case 'fc2':
                icona = 'pucfc2';
                break;
        }
    }

    const textAlt = 'Logo ' + (isOca ? 'canal ocasional' : __capitalize(canal));

    const textSR = (tipus === 'radio' ? 'Emissora: ' : 'Canal: ') + (isOca ? 'canal ocasional' : getNomCanal(canal));

    return !__isEmpty(canal) ? (
        <>
            <object
                className={stylesLogo}
                data={logosPath + icona + '.svg?v=1.2504.2'}
                type='image/svg+xml'
                tabIndex='-1'
                aria-label={customText || textAlt}
                aria-hidden={true}
                data-testid={`logoCanal-${icona}`}
            >
                {textAlt}
            </object>
            <span className='sr-only'>{customText || textSR}</span>
        </>
    ) : null;
}
